import React, { useEffect, useRef, useState } from "react"
import { StickyShareButtons } from "sharethis-reactjs"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import SEO from "./../components/SEO"
import H2 from "../components/core/H2"
import Layout from "../Layout"
import "./style.css"
import BlogPopup from "../components/blocks/Forms/BlogPopup"
const SubscribeForm = loadable(() => import("../components/blocks/SubscribeForm"))
const AuthorProfile = loadable(() => import("../components/blocks/AuthorProfile"))
const BlogCardBlock = loadable(() => import("../components/blocks/BlogCardBlock"))
// import SubscribeForm from "../components/blocks/SubscribeForm"
// import AuthorProfile from "../components/blocks/AuthorProfile"
// import BlogCardBlock from "../components/blocks/BlogCardBlock"

// import P from "../components/core/P"
const readingTime = require("reading-time")

const BlogTemplate = ({ data, location }) => {
  const [blogPopup, setBlogPopup] = useState(false)
  const [iframePopup, setIframePopup] = useState(false)
  const [popupData, setPopupData] = useState({ src: "", alt: "" });
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
  //const pathSegment = typeof window !== 'undefined' ? window.location.pathname.split('/').filter(Boolean).pop() : '';
  const allowedAlts = [
    "web-brochure",
    "mobile-portfolio",
    "ui-ux-brochure",
    "company-profile",
    "DevOps-Expertise-WebMobtech",
    "RealEstate-Domain-Expertise",
    "AI-Expertise-WebMobtech",
    "Product-Design-Expertise-WebMobtech",
    "SoftwareTesting-Expertise-WebMobtech",
  ];
  //  eslint-disable-next-line no-unused-vars
  const { wpPost } = data || {} // provide a default empty object to prevent "Cannot read property 'wpPost' of undefined"
  const { tags: { nodes } = undefined, BlogCTA: { ctaFields } = [] } = wpPost || {} // provide default empty objects to prevent "Cannot read property 'tags'/'BlogCTA' of undefined"
  const stats = readingTime(wpPost?.content)
  const contentRef = useRef(null);
  const handleImageClick = (src, alt) => {
    if (allowedAlts.includes(alt)) {
      // Store  clicked image's data
      setPopupData({ src, alt }); 
      // Show the popup
      setBlogPopup(true); 
    } else {
      setBlogPopup(false);
    }
  };
 
  useEffect(() => {
    if (contentRef.current) {
      const images = contentRef.current.querySelectorAll("img");

      images.forEach((img) => {
        const alt = img.getAttribute("alt");
        if (alt) {
          img.style.cursor = "pointer"; // Add pointer cursor for better UX
          img.addEventListener("click", () => handleImageClick(img.src, alt));
        }
      });

      // Clean up event listeners on component unmount
      return () => {
        images.forEach((img) => {
          const alt = img.getAttribute("alt");
          if (alt) {
            img.removeEventListener("click", () => handleImageClick(img.src, alt));
          }
        });
      };
    }
  }, [data?.wpPost?.content]);
  return (
    <>
      <Layout>
        <SEO
          title={data?.wpPost?.seo?.title}
          desc={data?.wpPost?.seo?.metaDesc}
          banner={data?.wpPost?.featuredImage?.node?.mediaItemUrl}
          ogurl={`/blog` + data?.wpPost?.seo?.opengraphUrl}
          ogtitle={data?.wpPost?.seo?.opengraphTitle}
          ogdesc={data?.wpPost?.seo?.opengraphDescription}
          ogbanner={data?.wpPost?.seo?.opengraphImage?.mediaItemUrl}
          tweettitle={data?.wpPost?.seo?.twitterTitle}
          tweetdesc={data?.wpPost?.seo?.twitterDescription}
          tweetbanner={data?.wpPost?.seo?.twitterImage?.mediaItemUrl}
          metaRobotsNoindex={data?.wpPage?.seo?.metaRobotsNoindex}
          canonical={`https://webmobtech.com${location.pathname}`}
          schemas={data?.wpPost?.JSONLdBlock?.jsonLd}
        />
        <div className="antialiased bg-white ">
          <StickyShareButtons
            config={{
              alignment: "left", // alignment of buttons (left, center, right)
              color: "social", // set the color of buttons (social, white)
              enabled: true, // show/hide buttons (true, false)
              font_size: 16, // font size for the buttons
              labels: "null", // button labels (cta, counts, null)
              language: "en", // which language to use (see LANGUAGES)
              networks: [
                // which networks to include (see SHARING NETWORKS)
                "whatsapp",
                "linkedin",
                "messenger",
                "facebook",
                "twitter",
              ],
              container: "x",
              id: "blog",
              padding: 12, // padding within buttons (INTEGER)
              radius: 4, // the corner radius on each button (INTEGER)
              show_total: true, // show/hide the total share count (true, false)
              show_mobile: true, // show/hide the buttons on mobile (true, false)
              show_toggle: true, // show/hide the toggle buttons (true, false)
              size: 48, // the size of each button (INTEGER)
              top: 250, // offset in pixels from the top of the page
              show_mobile_buttons: true,
              // OPTIONAL PARAMETERS
              image: wpPost?.featuredImage?.node?.mediaItemUrl, // (defaults to og:image or twitter:image)
              description: wpPost?.content, // (defaults to og:description or twitter:description)
              title: wpPost?.title, // (defaults to og:title or twitter:title)
              message: "Checkout this wonderfull blog.", // (only for email sharing)
              subject: `Checkout this wonderfull blog ${wpPost?.title}`, // (only for email sharing)
              username: "webmobtech", // (only for twitter sharing)
            }}
          />
          {blogPopup && <BlogPopup setIframePopup={setIframePopup} setBlogPopup={setBlogPopup} pageurl={currentUrl} type={popupData.alt} oldBrochureType={popupData.alt}/>}
          <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl pt-32 ">
            <main className="px-4 lg:px-8">
              <article className="xl:divide-y ">
                <header className="pt-6 xl:pb-10">
                  <div className="space-y-1 text-center">
                    <dl className="space-y-10">
                      <div>
                        {/* <dt className="sr-only">Published on</dt>
                      <dd className="text-base leading-6 font-medium text-gray-700">
                        <time dateTime="2020-08-27T18:30:00.000Z">
                          {wpPost.date}
                        </time>
                      </dd> */}
                      </div>
                    </dl>
                    <div>
                      <h1 className="text-3xl leading-9 font-bold text-shark-500 tracking-normal sm:text-4xl sm:leading-10 md:text-5xl md:leading-snug mb-5">
                        {wpPost.title}
                      </h1>
                      <div className="flex justify-center pb-2">
                        <div className="h-9 w-9 ml-4 rounded-2xl text-white text-base mr-2">
                          <a href={`/blog` + wpPost?.author?.node?.uri}>
                            <img
                              src={wpPost?.author?.node?.avatar?.url}
                              alt="author"
                              title="author"
                              loading="lazy"
                              height="100%"
                              width="100%"
                            />
                          </a>
                        </div>
                        <div className="flex items-center">
                          <a href={`/blog` + wpPost?.author?.node?.uri}>
                            <dd className="lg:text-base md:text-base text-xs leading-6 font-medium text-gray-700">
                              {wpPost?.author?.node?.name}
                            </dd>
                          </a>
                          <dd className="lg:text-base md:text-base text-xs leading-6 font-medium text-gray-700 ml-1">
                            <time dateTime="2020-08-27T18:30:00.000Z">
                              - {wpPost.date}
                            </time>
                          </dd>
                          <dd className="ml-3 lg:text-base md:text-base text-xs leading-6 font-medium text-gray-700">
                            ~ {stats.text}
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
                <div className="xl:pb-2 xl:col-span-3 xl:row-span-2">
                  {wpPost.featuredImage && (
                    <img
                      src={wpPost?.featuredImage?.node?.mediaItemUrl}
                      alt={wpPost?.featuredImage?.node?.altText}
                      title={wpPost?.featuredImage?.node?.altText}
                      loading="eager"
                      className="w-full h-auto bg-cover"
                      height="100%"
                      width="100%"
                    />
                  )}
                  <div className="xl:flex sm:block relative ">
                    <div
                      style={{ maxWidth: "70ch" }}
                      className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mx-auto mt-10 md:px-5 md:pt-5 md:pb-10 sm:w-full relative"
                    >
                    <div ref={contentRef} dangerouslySetInnerHTML={{ __html: wpPost.content }} />                      {/* <P title={wpPost.content} className="text-shark-400" /> */}
                      {/* <P title={wpPost.content} className="text-shark-400" /> */}
                      <p className="mt-8 mb-4 common-index">
                        {nodes &&
                          nodes.map(({ name, uri }) => (
                            <a
                              href={`/blog` + uri}
                              key={Math.random()}
                              style={{ textDecoration: "none" }}
                              className="text-sm font-bold m-1 inline-flex items-center leading-sm px-4 py-2 bg-blue-300 text-blue-600 bg-opacity-10 rounded"
                            >
                              {name}
                            </a>
                          ))}
                      </p>
                    </div>
                    {/* <div className={`xl:w-2/6 sm:w-full  xl:flex flex-col bg-white  relative  mt-10 `}>
                      {
                        ctaFields && ctaFields.length > 0 && (
                          <div>{
                            ctaFields.map((val, index) => {
                              return <div key={index} className={`shadow mt-10 rounded-lg rounded-t-xl self-start ${val?.image?.mediaItemUrl ? 'border-0' : 'border-t-4'} `} style={{ borderColor: `${val?.animationColor?.bgColor}` }}>
                                <div class=" bg-white rounded-lg shadow border-t-0 overflow-hidden">
                                  {val?.image && (
                                    <a href={val?.url?.url}>
                                      <img
                                        src={val?.image?.mediaItemUrl}
                                        alt={val?.image?.altText ? val?.image.altText : "Contact Us"}
                                        width={val?.image.mediaDetails.width}
                                        height={val?.image.mediaDetails.height}
                                        title="Tech Section"
                                        className="w-full h-full object-cover"
                                        loading="lazy"
                                      />
                                    </a>
                                  )}
                                  {(val?.title || val?.ctaLink?.title || val?.subtitle) && <div className="p-6">
                                    {val?.title && val?.url?.url && <a href={val?.url?.url}>
                                      <h3 class={`mb-2 text-2xl font-bold text-shark-500 ${val?.url?.url && "hover:text-blue-500"}`}>{val?.title}</h3>
                                    </a>}
                                    {
                                     val?.title && !val?.url?.url&& <h3 class={`mb-2 text-2xl font-bold text-shark-500 ${val?.url?.url && "hover:text-blue-500"}`}>{val?.title}</h3>
                                    }
                                    {val?.subtitle && <p class="mb-3 font-normal text-shark-500 ">{val?.subtitle}</p>}
                                    {val?.ctaLink?.title &&
                                      <a href={val?.ctaLink?.url} class="inline-flex items-center px-5 py-3 text-sm font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
                                        {val?.ctaLink?.title}
                                        <svg aria-hidden="true" class="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                      </a>}
                                  </div>}
                                </div>

                              </div>
                            })}
                          </div>
                        )
                      }
                      <div className="lg:sticky sm:relative lg:top-32 sm:top-0 my-10"> {/*gitex or ces top-32 else top-20 */}
                        {/* <SubscribeForm /> */}
                      {/* </div>
                    </div> */} 
                  </div>
                </div>
              </article>
            </main>
          </div>
          <AuthorProfile author={wpPost?.author} />

          {wpPost?.blocks &&
            wpPost?.blocks?.map((i, index) =>
              i.name === "acf/blog-card" ? (
                <div className="bg-blue-200">
                  <div
                    className="max-w-5xl mx-auto text-center py-12 px-4 bg-transparent"
                    data-aos="fade-up"
                  >
                    <div>
                      <H2 title="Check Related Blogs" />
                    </div>
                  </div>
                  <BlogCardBlock {...i} K="K" />
                </div>
              ) : (
                ""
              )
            )}
        </div>
      </Layout>
    </>
  )
}

export default BlogTemplate

export const query = graphql`
  query($databaseId: Int!) {
    wpPost(databaseId: { eq: $databaseId }) {
      id
      BlogCTA {
        ctaFields {
          title
          animationColor {
            bgColor
            textColor
          }
          subtitle
          ctaLink {
            title
            target
            url
          }
          image {
            mediaItemUrl
            srcSet
            altText
            mediaDetails {
              width
              height
            }
          }
          url {
            url
          }
        }
      }
      JSONLdBlock {
        jsonLd {
          jsonldSchema
        }
      }
      content
      title
      seo {
        title
        metaDesc
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphUrl
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
        metaRobotsNoindex
      }
      date(formatString: "ddd, MMM Do YYYY")
      tags {
        nodes {
          name
          uri
        }
      }
      featuredImage {
        node {
          mediaItemUrl
          altText
        }
      }
      author {
        node {
          firstName
          description
          lastName
          name
          avatar {
            default
            extraAttr
            forceDefault
            foundAvatar
            height
            rating
            scheme
            size
            url
            width
          }
          seo {
            social {
              linkedIn
              twitter
            }
          }
          uri
        }
      }
      blocks {
        name
        ... on WpAcfBlogCardBlock {
          BlogCard {
            blogs {
              ... on WpPost {
                title
                date(formatString: "MMM DD, YYYY")
                uri
                excerpt
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
                author {
                  node {
                    avatar {
                      url
                    }
                    name
                    uri
                  }
                }
                categories {
                  nodes {
                    name
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
